.Ratings {
    display: flex;
}

.Rating {
    display: flex;
    padding: 0 .5rem;
    align-items: center;
}

.RatingCount {
    font-size: 2rem;
    padding: 0 .5rem;
}

.Button {
    border: none;
    color: #000;
    background-color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 700;
}

.Button2 {
    color: #DB2929;
    background-color: #fff;
    margin: .5rem;
    border: none;
    padding: .5rem;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 700;
}

.Button3 {
    color: #002CB6;
    background-color: #fff;
    margin: .5rem;
    border: none;
    padding: .5rem;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 700;
}

.ratingList2 {
    display: none;
}

@media (max-width: 750px){
    .ratingList1 {
        display: none;
    }

    .ratingList2 {
        display: flex;
    }
}