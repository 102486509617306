.skfadingcircle {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .skfadingcircle .skcircle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .skfadingcircle .skcircle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: skcircleFadeDelay 1.2s infinite ease-in-out both;
            animation: skcircleFadeDelay 1.2s infinite ease-in-out both;
  }
  .skfadingcircle .skcircle2 {
    -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  .skfadingcircle .skcircle3 {
    -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  .skfadingcircle .skcircle4 {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .skfadingcircle .skcircle5 {
    -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
            transform: rotate(120deg);
  }
  .skfadingcircle .skcircle6 {
    -webkit-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
            transform: rotate(150deg);
  }
  .skfadingcircle .skcircle7 {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .skfadingcircle .skcircle8 {
    -webkit-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
            transform: rotate(210deg);
  }
  .skfadingcircle .skcircle9 {
    -webkit-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
            transform: rotate(240deg);
  }
  .skfadingcircle .skcircle10 {
    -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
            transform: rotate(270deg);
  }
  .skfadingcircle .skcircle11 {
    -webkit-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
            transform: rotate(300deg); 
  }
  .skfadingcircle .skcircle12 {
    -webkit-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
            transform: rotate(330deg); 
  }
  .skfadingcircle .skcircle2:before {
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s; 
  }
  .skfadingcircle .skcircle3:before {
    -webkit-animation-delay: -1s;
            animation-delay: -1s; 
  }
  .skfadingcircle .skcircle4:before {
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s; 
  }
  .skfadingcircle .skcircle5:before {
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s; 
  }
  .skfadingcircle .skcircle6:before {
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s; 
  }
  .skfadingcircle .skcircle7:before {
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s; 
  }
  .skfadingcircle .skcircle8:before {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s; 
  }
  .skfadingcircle .skcircle9:before {
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }
  .skfadingcircle .skcircle10:before {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .skfadingcircle .skcircle11:before {
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
  }
  .skfadingcircle .skcircle12:before {
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s;
  }
  
  @-webkit-keyframes skcircleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
  }
  
  @keyframes skcircleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; } 
  }