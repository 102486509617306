.PostUser {
    display: flex;
    flex-flow: column;
    padding: .5rem 1rem;
    padding-top: 1rem;
    align-items: center;
    background-color: cornsilk;

}

.ProfileLinkContainer {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    padding: 1rem;
    overflow: hidden;
}

.ProfileLink {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
}

.ProfilePicture {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
}

.TotalPosts {
    padding-top: 1rem;
    text-align: center;
}

.Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Name {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
}

@media (max-width: 768px){
    .PostUser {
        flex-flow: row;
        padding: 0;
    }
    .TotalPosts {
        padding: 0;
        padding-top: .5rem;
        text-align: left;
    }

    .Content {
        justify-content: flex-start;
    }

    .Name {
        text-align: left;
    }
    
}