.Post {
    display: flex;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .2);
}

.PostWrapper {
    padding: 2.5rem;
}

.message:hover{
    background-color: rgba(230,230,230,255);
}

.Button {
    display: inline-block;
    color: #fff;
    background-color: #1E5ECF;
    margin: .5rem;
    border: none;
    padding: .5rem;
    border-radius: 3px;
    cursor: pointer;
}

.MessagesButton {
    display: none;
}

.MessageContent, .MessageContentShow {
    flex-basis: 75%;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}

@media (max-width: 768px){
    .Post {
        flex-direction: column;
    }

    .PostWrapper {
        padding: 2.5rem 0;
    }

    .MessageContent {
        flex-basis: 0%;
        display: none;
    }

    .MessageContentShow {
        flex-basis: 100%;
        width: 100%;
    }

    .MessagesButton {
        display: inline-block;
        color: #fff;
        background-color: #1E5ECF;
        margin: .5rem;
        border: none;
        padding: .5rem;
        border-radius: 3px;
        cursor: pointer;
    }
}