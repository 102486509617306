.Post {
    display: flex;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .2);
}

.RightSide {
    display: flex;
    overflow: hidden;
    flex-flow: column;
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
}

.Content {
    margin-bottom: auto;
}

.PostWrapper {
    padding: 2.5rem;
}

/* .Button {
    color: #002CB6;
    background-color: #fff;
    margin: .5rem;
    border: none;
    padding: .5rem;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 700;
} */

.Button {
    display: inline-block;
    color: #fff;
    background-color: #1E5ECF;
    margin: .5rem;
    border: none;
    padding: .5rem;
    border-radius: 3px;
    cursor: pointer;
}

@media (max-width: 768px){
    .Post {
        flex-direction: column;
    }

    .PostWrapper {
        padding: 2.5rem 0;
    }
}