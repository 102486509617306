.loader {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
}
.loader div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loader div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes loader {
  0% {
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    opacity: 0;
  }
}
