.NavigationItem {
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
}

.NavigationItem a {
    font-size: 1.5rem;
    font-weight: 700;
    color: white;
    height: 100%;
    padding: 16px 10px;
    text-decoration: none;
    border-bottom: 4px solid transparent;
}

@media (max-width: 768px) {
    .NavigationItem {
        margin: 10px 0;
        box-sizing: border-box;
        display: block;
        width: 100%;
    }
    
    .NavigationItem a {
        font-size: 1.5rem;
        font-weight: 700;
        color: #000;
        width: 100%;
        box-sizing: border-box;
        display: block;
    }
}