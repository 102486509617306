.DrawerToggleMain {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    height: 100%;
    padding: 1rem 0;
    box-sizing: border-box;
    cursor: pointer;
}

.Line {
    width: 3rem;
    height: .5rem;
    background-color: #fff;
    display: inline-block;
    border-radius: .25rem;
}

@media (min-width: 769px) {
    .DrawerToggleMain {
        display: none;
    }
}