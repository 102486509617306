.ThreadPreview {
    display: flex;
    padding: 1rem;
    background-color: lightblue;
    align-items: center;
    font-size: 1rem;
}

.ThreadPreview:not(:last-child) {
    border-bottom: 2px solid rgb(73, 136, 187);
}

.PushRight {
    margin-right: auto;
}

.ReplyCount {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 3rem;
}

.ViewCount {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 3rem;
}

.LastPost {
    width: 22rem;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
}

.Name {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    width: 100%;
}

.Name:hover {
    color: #0059A7;
}

.Part1 {
    width: 35rem;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

@media (max-width: 900px){
    .ReplyCount {
        padding: 0;
    }
}

@media (max-width: 750px){
    .ReplyCount, .ViewCount {
        display: none;
    }
}

@media (max-width: 700px){
    .Part1 {
        width: 25rem;
    }

    .LastPost {
        width: 17rem;
    }
}

@media (max-width: 550px){
    .ThreadPreview {
        flex-direction: column;
    }

    .LastPost {
        display: none;
    }

    .Part1 {
        width: 100%;
    }
}