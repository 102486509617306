.Navbar {
    height: 7rem;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(26, 140, 211);
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0 1rem;
    box-sizing: border-box;
    z-index: 100;
}

.NavItems {
    display: flex;
}

@media (max-width: 768px) {
    .DesktopOnly {
        display: none;
    }
}