body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html {
  font-size: 62.5%; /* 1 rem = 10px, 10px/16px = 62.5% */
}

pre {
  margin: 0 0;
}

code {
  padding: 1em !important;
}

/*
 * Visual Studio 2015 dark style
 * Author: Nicolas LLOBERA <nllobera@gmail.com>
 */

 .hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #1E1E1E;
  color: #DCDCDC;
}

.hljs-keyword,
.hljs-literal,
.hljs-symbol,
.hljs-name {
  color: #569CD6;
}
.hljs-link {
  color: #569CD6;
  text-decoration: underline;
}

.hljs-built_in,
.hljs-type {
  color: #4EC9B0;
}

.hljs-number,
.hljs-class {
  color: #B8D7A3;
}

.hljs-string,
.hljs-meta-string {
  color: #D69D85;
}

.hljs-regexp,
.hljs-template-tag {
  color: #9A5334;
}

.hljs-subst,
.hljs-function,
.hljs-title,
.hljs-params,
.hljs-formula {
  color: #DCDCDC;
}

.hljs-comment,
.hljs-quote {
  color: #57A64A;
  font-style: italic;
}

.hljs-doctag {
  color: #608B4E;
}

.hljs-meta,
.hljs-meta-keyword,
.hljs-tag {
  color: #9B9B9B;
}

.hljs-variable,
.hljs-template-variable {
  color: #BD63C5;
}

.hljs-attr,
.hljs-attribute,
.hljs-builtin-name {
  color: #9CDCFE;
}

.hljs-section {
  color: gold;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

/*.hljs-code {
  font-family:'Monospace';
}*/

.hljs-bullet,
.hljs-selector-tag,
.hljs-selector-id,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo {
  color: #D7BA7D;
}

.hljs-addition {
  background-color: #144212;
  display: inline-block;
  width: 100%;
}

.hljs-deletion {
  background-color: #600;
  display: inline-block;
  width: 100%;
}
.PostEditor_TextArea__28Nk3 {
    flex: 0 0 50%;
    padding: .5rem;
    box-sizing: border-box;
}

.PostEditor_PostContent__1LXWQ {
    flex: 0 0 50%;
    padding: .5rem;
    box-sizing: border-box;
    min-width: 0;
}

.PostEditor_TextAreaPreview__3X8_d {
    flex: 0 0 50%;
    padding: .5rem;
    box-sizing: border-box;
}

.PostEditor_PostContentPreview__Cg9SG {
    flex: 0 0 50%;
    padding: .5rem;
    box-sizing: border-box;
    min-width: 0;
}

.PostEditor_styleButton__1uZJK, .PostEditor_styleButton2__3c83d, .PostEditor_styleButton3__3teTZ, .PostEditor_styleButton4__Um1Kx, .PostEditor_styleButton5__sA10A, .PostEditor_styleButton6__3tuc5, .PostEditor_styleButton7__2rR8q {
    display: inline-block;
    color: #fff;
    background-color: #1E5ECF;
    margin: .5rem;
    border: none;
    padding: .5rem;
    border-radius: 3px;
    cursor: pointer;
}

.PostEditor_styleButton5__sA10A {
    display: none;
}

@media (max-width: 950px){
    .PostEditor_styleButton2__3c83d {
        display: none;
    }
}

@media (max-width: 850px){
    .PostEditor_styleButton3__3teTZ {
        display: none;
    }
}

@media (max-width: 768px){
    .PostEditor_TextArea__28Nk3 {
        flex: 0 0 100%;
    }

    .PostEditor_PostContent__1LXWQ {
        display: none;
    }

    .PostEditor_TextAreaPreview__3X8_d {
        display: none;
    }

    .PostEditor_PostContentPreview__Cg9SG {
        flex: 0 0 100%;
    }
}

@media (max-width: 675px){
    .PostEditor_styleButton4__Um1Kx {
        display: none;
    }

    .PostEditor_styleButton5__sA10A {
        display: inline-block;
    }
}

@media (max-width: 600px){
    .PostEditor_styleButton__1uZJK {
        font-size: 1.2rem;
    }
}

@media (max-width: 475px){
    .PostEditor_styleButton6__3tuc5 {
        display: none;
    }
}

@media (max-width: 375px){
    .PostEditor_styleButton7__2rR8q {
        display: none;
    }
}
.Spinner2_skfadingcircle__1kfTn {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle__2yzfr {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle__2yzfr:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: Spinner2_skcircleFadeDelay__2ZWr- 1.2s infinite ease-in-out both;
            animation: Spinner2_skcircleFadeDelay__2ZWr- 1.2s infinite ease-in-out both;
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle2__3LZn5 {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle3__9drg5 {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle4__mQFJ9 {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle5__1k7WS {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg);
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle6__3nGAB {
    -webkit-transform: rotate(150deg);
            transform: rotate(150deg);
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle7__2_NBt {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle8__gTLFT {
    -webkit-transform: rotate(210deg);
            transform: rotate(210deg);
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle9__1qRFN {
    -webkit-transform: rotate(240deg);
            transform: rotate(240deg);
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle10__2O0Dr {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle11__MP_RG {
    -webkit-transform: rotate(300deg);
            transform: rotate(300deg); 
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle12__2DNHx {
    -webkit-transform: rotate(330deg);
            transform: rotate(330deg); 
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle2__3LZn5:before {
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s; 
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle3__9drg5:before {
    -webkit-animation-delay: -1s;
            animation-delay: -1s; 
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle4__mQFJ9:before {
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s; 
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle5__1k7WS:before {
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s; 
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle6__3nGAB:before {
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s; 
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle7__2_NBt:before {
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s; 
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle8__gTLFT:before {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s; 
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle9__1qRFN:before {
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle10__2O0Dr:before {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle11__MP_RG:before {
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
  }
  .Spinner2_skfadingcircle__1kfTn .Spinner2_skcircle12__2DNHx:before {
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s;
  }
  
  @-webkit-keyframes Spinner2_skcircleFadeDelay__2ZWr- {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
  }
  
  @keyframes Spinner2_skcircleFadeDelay__2ZWr- {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; } 
  }
.TreeHierarchy_NodeSelection__A6vFw{
    cursor: pointer;
    display: inline-block;
    font-size: 1.5rem;
    padding-bottom: .5rem;
}
.TreeHierarchy_ThreadSelection__sJFtq{
    cursor: pointer;
    display: inline-block;
    font-size: 1.5rem;
    padding-bottom: .5rem;
}
.TreeHierarchy_NodeSelection__A6vFw:hover{
    background-color: #bbb;
}
.TreeHierarchy_ThreadSelection__sJFtq:hover{
    background-color: rgb(114, 201, 230);
}
.PostForm_Preview__EEIWe {
    display: none;
}

@media (max-width: 768px) {
    .PostForm_Preview__EEIWe {
        display: inline-block;
        color: #fff;
        background-color: #822ac9;
        margin: .5rem;
        border: none;
        padding: .5rem;
        border-radius: 3px;
        cursor: pointer;
    }
}
.SubCategory_SubCategory__1JKl4 {
    display: flex;
    padding: 1rem;
    background-color: lightblue;
    align-items: center;
    font-size: 1rem;
}



.SubCategory_SubCategory__1JKl4:not(:last-child) {
    border-bottom: 2px solid rgb(73, 136, 187);
}

.SubCategory_PushRight__2-13c {
    margin-right: auto;
}

.SubCategory_PostCount__1KFJK {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 3rem;
}

.SubCategory_LastThreadInfo__3gZbq {
    width: 22rem;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
}

.SubCategory_lastThreadLink__1RRUJ, .SubCategory_Title__17yXS {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    width: 100%;
}

.SubCategory_lastThreadLink__1RRUJ:hover .SubCategory_Name__1cvI9, .SubCategory_Title__17yXS:hover {
    color: #0059A7;
}


.SubCategory_Name__1cvI9, .SubCategory_Title__17yXS {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

/* .Name:hover {
    color: #0059A7;
} */

.SubCategory_Part1__auCQe {
    width: 35rem;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

@media (max-width: 750px){
    .SubCategory_PostCount__1KFJK {
        display: none;
    }
}

@media (max-width: 700px){
    .SubCategory_Part1__auCQe {
        width: 25rem;
    }

    .SubCategory_LastThreadInfo__3gZbq {
        width: 17rem;
    }
}

@media (max-width: 550px){
    .SubCategory_SubCategory__1JKl4 {
        flex-direction: column;
    }

    .SubCategory_LastThreadInfo__3gZbq {
        margin-top: 1rem;
        margin-left: auto;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        margin-right: 2rem;
    }

    .SubCategory_User__2_7N_, .SubCategory_Name__1cvI9, .SubCategory_NameNone__2BRMB {
        display: none;
    }

    .SubCategory_Date__NIwgH {
        text-decoration: underline;
    }

    .SubCategory_Date__NIwgH:hover {
        color: #0059A7;
    }

    .SubCategory_Part1__auCQe {
        width: 100%;
    }
}
.Category_Category__2iFfl {
    margin: 2rem;
    border-radius: 3px;
    
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .2);
    position: relative;
}

.Category_Header__2q6H5 {
    font-size: 2rem;
    padding: .5rem;
    background-color: rgb(56, 121, 173);
    color: white;
    cursor: pointer;
}

.Category_Opened__3GMYH{
    -webkit-transition: all .5s;
    transition: all .5s;
    overflow: hidden;
}

.Category_Closed__1t2Mb{
    -webkit-transition: all .5s;
    transition: all .5s;
    max-height: 0px;
    visibility: hidden;
    overflow: hidden;
}

.Category_Wrap__29irv {
    overflow: hidden;
    height: 100%;
}
.ThreadPreview_ThreadPreview__2zetf {
    display: flex;
    padding: 1rem;
    background-color: lightblue;
    align-items: center;
    font-size: 1rem;
}

.ThreadPreview_ThreadPreview__2zetf:not(:last-child) {
    border-bottom: 2px solid rgb(73, 136, 187);
}

.ThreadPreview_PushRight__FL7DH {
    margin-right: auto;
}

.ThreadPreview_ReplyCount__3LHzt {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 3rem;
}

.ThreadPreview_ViewCount__3mymU {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 3rem;
}

.ThreadPreview_LastPost__2tXdc {
    width: 22rem;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
}

.ThreadPreview_Name__3pUfn {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    width: 100%;
}

.ThreadPreview_Name__3pUfn:hover {
    color: #0059A7;
}

.ThreadPreview_Part1__JOsjt {
    width: 35rem;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

@media (max-width: 900px){
    .ThreadPreview_ReplyCount__3LHzt {
        padding: 0;
    }
}

@media (max-width: 750px){
    .ThreadPreview_ReplyCount__3LHzt, .ThreadPreview_ViewCount__3mymU {
        display: none;
    }
}

@media (max-width: 700px){
    .ThreadPreview_Part1__JOsjt {
        width: 25rem;
    }

    .ThreadPreview_LastPost__2tXdc {
        width: 17rem;
    }
}

@media (max-width: 550px){
    .ThreadPreview_ThreadPreview__2zetf {
        flex-direction: column;
    }

    .ThreadPreview_LastPost__2tXdc {
        display: none;
    }

    .ThreadPreview_Part1__JOsjt {
        width: 100%;
    }
}
.SubCategoryPage_Header__3ZAjc {
    font-size: 3rem;
    margin: 4rem;
    margin-bottom: 2rem;
}

.SubCategoryPage_SubCat__1LBpw {
    margin-bottom: 5rem;
}
.PostUser_PostUser__DD-vB {
    display: flex;
    flex-flow: column;
    padding: .5rem 1rem;
    padding-top: 1rem;
    align-items: center;
    background-color: cornsilk;

}

.PostUser_ProfileLinkContainer__33-6X {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    padding: 1rem;
    overflow: hidden;
}

.PostUser_ProfileLink__1FSJW {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
}

.PostUser_ProfilePicture__8_jXz {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
}

.PostUser_TotalPosts__13_tH {
    padding-top: 1rem;
    text-align: center;
}

.PostUser_Content__3ZoF0 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.PostUser_Name__2cCL9 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
}

@media (max-width: 768px){
    .PostUser_PostUser__DD-vB {
        flex-flow: row;
        padding: 0;
    }
    .PostUser_TotalPosts__13_tH {
        padding: 0;
        padding-top: .5rem;
        text-align: left;
    }

    .PostUser_Content__3ZoF0 {
        justify-content: flex-start;
    }

    .PostUser_Name__2cCL9 {
        text-align: left;
    }
    
}
.Ratings_Ratings__2VGBd {
    display: flex;
}

.Ratings_Rating__3tuGO {
    display: flex;
    padding: 0 .5rem;
    align-items: center;
}

.Ratings_RatingCount__176N1 {
    font-size: 2rem;
    padding: 0 .5rem;
}

.Ratings_Button__oeXAO {
    border: none;
    color: #000;
    background-color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 700;
}

.Ratings_Button2__3qm7Z {
    color: #DB2929;
    background-color: #fff;
    margin: .5rem;
    border: none;
    padding: .5rem;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 700;
}

.Ratings_Button3__2z3pV {
    color: #002CB6;
    background-color: #fff;
    margin: .5rem;
    border: none;
    padding: .5rem;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 700;
}

.Ratings_ratingList2__37GkB {
    display: none;
}

@media (max-width: 750px){
    .Ratings_ratingList1__3c3JI {
        display: none;
    }

    .Ratings_ratingList2__37GkB {
        display: flex;
    }
}
.Post_Post__3b7Be {
    display: flex;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .2);
}

.Post_RightSide__3ypV5 {
    display: flex;
    overflow: hidden;
    flex-flow: column;
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
}

.Post_Content__1wFtc {
    margin-bottom: auto;
}

.Post_PostWrapper__3U9EC {
    padding: 2.5rem;
}

/* .Button {
    color: #002CB6;
    background-color: #fff;
    margin: .5rem;
    border: none;
    padding: .5rem;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 700;
} */

.Post_Button__3N3pu {
    display: inline-block;
    color: #fff;
    background-color: #1E5ECF;
    margin: .5rem;
    border: none;
    padding: .5rem;
    border-radius: 3px;
    cursor: pointer;
}

@media (max-width: 768px){
    .Post_Post__3b7Be {
        flex-direction: column;
    }

    .Post_PostWrapper__3U9EC {
        padding: 2.5rem 0;
    }
}
.Thread_Header__3w5uI {
    font-size: 3rem;
    margin: 4rem;
}
.Spinner_loader__3M_kK {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
}
.Spinner_loader__3M_kK div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: Spinner_loader__3M_kK 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: Spinner_loader__3M_kK 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.Spinner_loader__3M_kK div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
@-webkit-keyframes Spinner_loader__3M_kK {
  0% {
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    opacity: 0;
  }
}
@keyframes Spinner_loader__3M_kK {
  0% {
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    opacity: 0;
  }
}

.MessagePage_Post__2svWZ {
    display: flex;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .2);
}

.MessagePage_PostWrapper__2lk3V {
    padding: 2.5rem;
}

.MessagePage_message__WdU1S:hover{
    background-color: rgba(230,230,230,255);
}

.MessagePage_Button__2y70G {
    display: inline-block;
    color: #fff;
    background-color: #1E5ECF;
    margin: .5rem;
    border: none;
    padding: .5rem;
    border-radius: 3px;
    cursor: pointer;
}

.MessagePage_MessagesButton__1K84m {
    display: none;
}

.MessagePage_MessageContent__LEsTO, .MessagePage_MessageContentShow__wPQT7 {
    flex-basis: 75%;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}

@media (max-width: 768px){
    .MessagePage_Post__2svWZ {
        flex-direction: column;
    }

    .MessagePage_PostWrapper__2lk3V {
        padding: 2.5rem 0;
    }

    .MessagePage_MessageContent__LEsTO {
        flex-basis: 0%;
        display: none;
    }

    .MessagePage_MessageContentShow__wPQT7 {
        flex-basis: 100%;
        width: 100%;
    }

    .MessagePage_MessagesButton__1K84m {
        display: inline-block;
        color: #fff;
        background-color: #1E5ECF;
        margin: .5rem;
        border: none;
        padding: .5rem;
        border-radius: 3px;
        cursor: pointer;
    }
}
.MessageSideBar_message__1rBXK {
    cursor: pointer;
}

.MessageSideBar_message__1rBXK:hover{
    background-color: rgba(230,230,230,255);
}

.MessageSideBar_DeleteButton__TSqVx {
    display: inline-block;
    color: #fff;
    background-color: #db2929;
    margin: .5rem;
    border: none;
    padding: .5rem;
    border-radius: 3px;
    cursor: pointer;
}

.MessageSideBar_Button__3PeTO {
    display: inline-block;
    color: #fff;
    background-color: #1E5ECF;
    margin: .5rem;
    border: none;
    padding: .5rem;
    border-radius: 3px;
    cursor: pointer;
}

.MessageSideBar_SideBar__1z5l0, .MessageSideBar_SideBarClosed__2KT5I {
    flex-basis: 25%;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-right: 1px solid rgba(230,230,230,255);
}

.MessageSideBar_bottomButtons__yLt5o {
    height: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

@media (max-width: 768px){
    .MessageSideBar_SideBar__1z5l0 {
        flex-basis: 100%;
        width: 100%;
    }

    .MessageSideBar_SideBarClosed__2KT5I {
        flex-basis: 0%;
        display: none;
    }

    .MessageSideBar_bottomButtons__yLt5o {
        display: none;
    }
}
.Forums_Forums__1U47f {
    padding-top: 7rem;
}

.Forums_fade__34B-q {
    opacity: 1;
}

.Forums_fade-enter__3zvts {
    opacity: 0;
}

.Forums_fade-enter-active__2LeHi {
    opacity: 1;
    -webkit-transition: all 10s;
    transition: all 10s;
}

.Forums_fade-enter-done__2Y9qo {
    opacity: 1;
    -webkit-transition: all 10s;
    transition: all 10s;
}

.Forums_fade-exit__2WUkf {
    opacity: 1;
}

.Forums_fade-exit-active__3tbQl {
    opacity: 0;
    -webkit-transition: all 10s;
    transition: all 10s;
}

.Forums_fade-exit-done__29d7h {
    opacity: 0;
    -webkit-transition: all 10s;
    transition: all 10s;
}

.Forums_fade-appear__2QeZL {
    opacity: 0;
}

.Forums_fade-appear-active__2dTRM {
    opacity: 1;
    -webkit-transition: all 10s;
    transition: all 10s;
}
.Layout_Loading__3smIj{
    -webkit-transition: all .5s;
    transition: all .5s;
    opacity: 0;
}

.Layout_Loaded__3NV5g{
    -webkit-transition: all .5s;
    transition: all .5s;
    opacity: 1;
}
.Navbar_Navbar__1Id3n {
    height: 7rem;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(26, 140, 211);
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0 1rem;
    box-sizing: border-box;
    z-index: 100;
}

.Navbar_NavItems__3I9Su {
    display: flex;
}

@media (max-width: 768px) {
    .Navbar_DesktopOnly__1Bwer {
        display: none;
    }
}
.DrawerToggle_DrawerToggleMain__2ldB7 {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    height: 100%;
    padding: 1rem 0;
    box-sizing: border-box;
    cursor: pointer;
}

.DrawerToggle_Line__3bNmR {
    width: 3rem;
    height: .5rem;
    background-color: #fff;
    display: inline-block;
    border-radius: .25rem;
}

@media (min-width: 769px) {
    .DrawerToggle_DrawerToggleMain__2ldB7 {
        display: none;
    }
}
.SideDrawer_SideDrawer__3Hdkj {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    padding: 32px 16px;
    box-sizing: border-box;
}

.SideDrawer_Open__6ke_k {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.SideDrawer_Close__3iitx {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}

.SideDrawer_Logo__1ii0g {
    height: 11%;
    margin-bottom: 32px;
}

@media (max-width: 500px) {
    .SideDrawer_SideDrawer__3Hdkj {
        -webkit-transition: -webkit-transform 0.3s ease-out;
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    }
}
.Backdrop_Backdrop__3oM0L {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
.NavigationItem_NavigationItem__12NRa {
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
}

.NavigationItem_NavigationItem__12NRa a {
    font-size: 1.5rem;
    font-weight: 700;
    color: white;
    height: 100%;
    padding: 16px 10px;
    text-decoration: none;
    border-bottom: 4px solid transparent;
}

@media (max-width: 768px) {
    .NavigationItem_NavigationItem__12NRa {
        margin: 10px 0;
        box-sizing: border-box;
        display: block;
        width: 100%;
    }
    
    .NavigationItem_NavigationItem__12NRa a {
        font-size: 1.5rem;
        font-weight: 700;
        color: #000;
        width: 100%;
        box-sizing: border-box;
        display: block;
    }
}
